<template>
<div class="title-wrap">
    <h1 class="one-title">
        <slot></slot>
    </h1>
    <p class="two-title">
        <slot name="two-title"></slot>
    </p>
</div>
</template>

<script>
export default {
    name: 'Title',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.title-wrap {
    text-align: center;
    padding: 100px 0 75px;

    .one-title {
        font-size: 36px;
        font-family: Source Han Sans CN;
        color: #555555;
    }

    .two-title {
        font-size: 20px;
        margin-top: 5px;
        color: #555555;
    }
}
</style>
