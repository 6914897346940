
import navMenu from '@/components/navMenu.vue'
import Title from '@/components/Title.vue'
import SuspendBar from '@/components/SuspendBar.vue'
import applyDemoForm from '@/components/applyDemoForm.vue'
import BottomDemoDiv from '@/components/BottomDemoDiv.vue'
import PartnerScroll from '@/components/PartnerScroll.vue'
import AppLink from '@/components/AppLink.vue'
export default {
  install(Vue) {
    Vue.component('navMenu', navMenu);
    Vue.component('Title', Title);
    Vue.component('SuspendBar', SuspendBar);
    Vue.component('applyDemoForm', applyDemoForm);
    Vue.component('BottomDemoDiv', BottomDemoDiv);
    Vue.component('PartnerScroll', PartnerScroll);
    Vue.component('AppLink', AppLink);
  }
}