<template>
<div class="wrap" ref="viewtools">
    <el-popover placement="left-start" width="160" trigger="hover">
        <div class="wx-content flex flex-column">
            <img class="code-img" src="@/assets/code.png" alt="">
            <p class="font-size-14">扫码立即咨询</p>
            <p class="font-size-14">免费申请演示试用</p>
        </div>
        <div slot="reference" class="wx font-size-20 flex flex-column flex-row-center">
            <div class="wx-img m-b-10"></div>
            <p>微信咨询</p>
        </div>
    </el-popover>
    <el-popover placement="left" width="190" trigger="hover">
        <div class="serve-content">
            <p class="font-size-14" style=" cursor: pointer" @click="applyDemoFormOpen">点击立即咨询</p>
            <p class="font-size-14 main-color" style="cursor: pointer" @click="applyDemoFormOpen">为您推荐最合适的产品 ></p>
        </div>
        <div slot="reference" class="serve font-size-16 flex flex-column flex-row-center">
            <div class="serve-img m-b-10"></div>
            <p>业务咨询</p>
        </div>
    </el-popover>
    <el-popover placement="left" width="190" trigger="hover">
        <div class="demo-content">
            <p class="font-size-14" @click="applyDemoFormOpen" style="cursor: pointer">点击立即咨询</p>
            <p class="font-size-14 main-color" @click="applyDemoFormOpen" style="cursor: pointer">免费申请演示试用 ></p>
        </div>
        <div slot="reference" class="demo font-size-16">
            <div class="flex flex-column flex-row-center">
                <div class="demo-img m-b-10"></div>
                <p>申请试用</p>
            </div>
        </div>
    </el-popover>
    <el-backtop :right="20" :bottom="backBottom">
        <i class="el-icon-arrow-up font-size-30" style="color:#999"></i>
    </el-backtop>
</div>
</template>

<script>
  export default {
      name: 'SuspendBar',
      props: {
          msg: String
      },
      data() {
          return {
              backBottom: null,
              timer: null, // 记录setTimeout
          };
      },
      mounted() {
          this.$nextTick(() => {
              this.backBottom = window.innerHeight - (this.$refs.viewtools.getBoundingClientRect().top + this.$refs.viewtools.clientHeight + 100);
              // window.addEventListener('resize', this.bottomResize);
              // this.$on("hook:beforeDestroy", () => {
              //    window.removeEventListener('resize', this.bottomResize);
              // })
              // 或者
              // window.addEventListener('resize', this.bottomResize);
              // this.$once("hook:beforeDestroy", () => {
              //   window.removeEventListener('resize', this.bottomResize);
              // })
          })
      },
      methods: {
          bottomResize() {
              //防抖
              if (this.timer)
                  clearTimeout(this.timer)
              this.timer = setTimeout(() => {
                  this.backBottom = window.innerHeight - (this.$refs.viewtools.getBoundingClientRect().top + this.$refs.viewtools.clientHeight + 150);
              }, 500)
          },
          applyDemoFormOpen() {
              this.$emit("applyDemoFormOpen");
          },
      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.el-backtop {
    width: 50px;
    height: 50px;
}

.wrap {
    position: fixed;
    right: 20px;
    top: 20%;
    width: 50px;
    z-index: 999;

    .wx-content {
        .code-img {
            width: 111px;
            height: 111px;
        }
    }

    .wx {
        height: 154px;
        background: #FFFFFF;
        border-radius: 25px;
        cursor: pointer;

        &:hover {
            .wx-img {
                background: url(@/assets/wechat1.png) center no-repeat;
            }

            p {
                color: #3699FF;
            }
        }

        .wx-img {
            width: 28px;
            height: 28px;
            background: url(@/assets/wechat0.png) center no-repeat;
            background-size: cover;
        }

        p {
            writing-mode: tb-rl;
        }
    }

    .serve {
        height: 110px;
        background: #FFFFFF;
        border-radius: 25px 25px 0 0;
        padding: 0 5px;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
            .serve-img {
                background: url(@/assets/serve1.png) center no-repeat;
            }

            p {
                color: #3699FF;
            }
        }

        .serve-img {
            width: 28px;
            height: 28px;
            background: url(@/assets/serve0.png) center no-repeat;
            background-size: cover;
        }
    }

    .demo {
        height: 120px;
        background: #FFFFFF;
        border-radius: 0 0 25px 25px;
        padding: 0 8px;
        text-align: center;
        cursor: pointer;

        &:hover {
            .demo-img {
                background: url(@/assets/demo1.png) center no-repeat;
            }

            p {
                color: #3699FF;
            }
        }

        &>div {
            border-top: 1px solid #999;
        }

        .demo-img {
            width: 28px;
            height: 28px;
            background: url(@/assets/demo0.png) center no-repeat;
            background-size: cover;
            margin-top: 10px;
        }
    }
}
</style>
