<template>
<header :class="[darkBgColor ? 'dark-bg' : '']">
    <nav class="flex">
        <div class="logo-img">
            <img alt="logo" src="@/assets/logo.png">
        </div>
        <ul class="flex">
            <li class="nav-item" :class="classS1(index)" v-for="(nav,index) in menuList">
                <!-- <li class="nav-item" :class="[white?'white':'',menuIndex==index?'active-menu':'']" v-for="(nav,index) in menuList"> -->
                <AppLink v-if="nav.path" :to="nav.path">
                    {{ !nav.subList ? nav.title: '' }}
                </AppLink>
                <!-- <el-popover v-if="nav.subList" placement="bottom" width="1300" trigger="manual" v-model="visible"> -->
                <el-popover v-if="nav.subList" placement="bottom" width="1000" trigger="hover">
                    <div class="flex flex-row-between p-l-70 p-r-70">
                        <ul v-for="menu in nav.subList">
                            <li class="sub-menu-title font-size-20 m-b-30">{{ menu.title }}</li>
                            <li class="sub-menu-item m-b-20" v-for="subMenu in menu.list">
                                <AppLink v-if="subMenu.path" :to="subMenu.path">
                                    <div class="sub-menu-item-title font-size-16">{{ subMenu.subTitle }}</div>
                                    <div class="sub-menu-item-desc font-size-12 font-gray m-t-5">{{ subMenu.desc }}</div>
                                </AppLink>
                            </li>
                        </ul>
                    </div>
                    <!-- <div slot="reference" @click="visible = !visible">{{ nav.subList ? nav.title: '' }}</div> -->
                    <div slot="reference">{{ nav.subList ? nav.title: '' }}</div>
                </el-popover>
            </li>
        </ul>
        <!-- <el-button type="primary" size="mini" @click="applyDemoFormOpen"><img alt="logo" src="@/assets/demo.png"> 免费演示试用</el-button> -->
        <AppLink to="/demoList">
            <el-button type="primary" size="mini"><img alt="logo" src="@/assets/demo.png"> 免费演示试用</el-button>
        </AppLink>
    </nav>
</header>
</template>

<script>
export default {
    name: 'navMenu',
    props: {
        white: Boolean,
        menuIndex: Number,
        darkBgColor: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visible: false,
            menuList: [{
                    title: '首页',
                    path: '/'
                },
                {
                    title: '产品与解决方案',
                    subList: [{
                            title: '数字协同',
                            list: [{
                                    subTitle: '数智工厂',
                                    desc: '车辆违规告警、智能排号',
                                    index: 1,
                                    path: '/wisdomPlant'
                                },
                                {
                                    subTitle: '交通云',
                                    desc: '车联网综合管理应用',
                                    index: 2,
                                    path: '/trafficCloud'
                                },
                                {
                                    subTitle: 'CRM-客户管理系统',
                                    desc: '管理客户关系，提高销售线索转化率',
                                    index: 3,
                                    path: '/crm'
                                }
                            ]
                        },

                        {
                            title: '智慧物流',
                            list: [{
                                    subTitle: 'TMS-物流运输管理系统',
                                    desc: '承运商 司机管理 调度管理',
                                    index: 4,
                                    path: '/tms'
                                },
                                {
                                    subTitle: '网络货运',
                                    desc: '货源大厅 线上支付 开票管理',
                                    index: 5,
                                    path: '/freight'
                                },
                                {
                                    subTitle: '车载哪',
                                    desc: '车联网数据综合服务平台',
                                    index: 6,
                                    path: '/car'
                                }
                            ]
                        },
                        {
                            title: '数字采购',
                            list: [{
                                    subTitle: 'S2B2C商城',
                                    desc: '全场景，一站式采购',
                                    index: 7,
                                    path: '/store'
                                },
                                {
                                    subTitle: '设备租赁平台',
                                    desc: '设备共享租赁，提高利用率',
                                    index: 8,
                                    path: '/leasing'
                                },
                                {
                                    subTitle: '车后市场',
                                    desc: '保险、ETC、油气',
                                    index: 9,
                                    path: '/carMarket'
                                }
                            ]
                        }
                    ]
                },
                // {title:'客户案例',path:'/case'},
                // {
                //     title: '好运云链',
                //     // path: '/'
                //     path: '/cloudChain'
                // },
                {
                    title: '关于我们',
                    // path: '/'
                    path: '/about'
                },
            ],
        };
    },
    methods: {
        classS1(index) {
            let v = [];
            if (this.menuIndex == index) {
                v.push("active-menu");
            }
            if (this.white) {
                v.push("white")
            }
            return v;
        },
        applyDemoFormOpen() {
            this.$emit("applyDemoFormOpen");
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.el-button--mini {
    font-size: 18px;
    width: 180px;
    height: 46px;
    img{
        // width: 20px;
        // height: 20px;
        position: relative;
        top: -1px;
    }
    span{
        display: flex;
        align-items: center;
    }
}

header {
    
    padding: 21px 0;

    .logo-img {
        margin-right: 230px;

        img {
            width: 254px;
            height: 44px;
        }
    }

    nav {
        margin: auto;
        width: 1300px;
        ul {
            .nav-item {
                height: 50px;
                line-height: 50px;
                // padding: 0 20px;
                margin-right: 56px;
                cursor: pointer;
                font-size: 18px;
                color: #555;
                position: relative;
            }

            .active-menu {
                font-weight: bold;

                &::after {
                    content: "";
                    background: url(@/assets/icon/icon-up.png) center no-repeat;
                    background-size: cover;
                    width: 10px;
                    height: 6px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%);
                }

            }
        }
    }
}

.sub-menu-title {
    // text-decoration: underline;
    position: relative;
    line-height: 50px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 162px;
        height: 1px;
        background: #DCDCDC;
    }
}

.sub-menu-item {
    // cursor: pointer;
    a{
        &:hover{
            color: #3699FF;
            .sub-menu-item-desc {
                color: #3699FF;
            }
        }
    }
    .sub-menu-item-desc {
        color: #999;
    }
}

.white {
    color: #fff !important;

    a {
        color: #fff !important;
    }
}
.dark-bg{
    background-color: #000C36;
}
</style>
