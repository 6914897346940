<template>
<footer>
    <div class="info-wrap w1300 flex flex-row-center">
        <div class="flex w1300 p-t-50 p-b-50 flex-row-between">
        <!-- <div class="flex p-t-50 p-b-50"> -->
            <div class="left-info">
                <div><img src="@/assets/logo2.png" alt=""></div>
                <ul class="m-t-35 m-b-35">
                    <li class="font-size-16" v-for="item in companyInfoList">{{ item.title }}{{ item.content }}</li>
                </ul>
                <div class="flex">
                    <div class="flex flex-column m-r-45">
                        <div class="wx-gzh">
                            <img src="@/assets/code.png" alt="微信公众号">
                        </div>
                        <p class="m-t-10 font-size-16">微信公众号</p>
                    </div>
                    <!-- <div class="flex flex-column">
                        <div class="wx-kf">
                            <img src="@/assets/code.png" alt="微信客服">
                        </div>
                        <p class="m-t-10 font-size-16">微信客服</p>
                    </div> -->
                </div>
            </div>
            <div class="right-info flex flex-col-top">
                <ul v-for="item in productList">
                    <li class="font-size-18 title m-b-30">{{ item.title }}</li>
                    <li class="font-size-14 item m-b-20" v-for="sub in item.list">
                        <AppLink :to="sub.path">{{ sub.subTitle }}</AppLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="copy-right">
        Copyright © 2006 - 2022 福建好运技术科技有限公司 版权所有 闽ICP备2021010614号
    </div>
</footer>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        msg: String
    },
    data() {
        return {
            companyInfoList: [{
                    title: '商务合作：',
                    content: '18038180505'
                },
                {
                    title: '平台客服：',
                    content: '400-6262-968'
                },
                {
                    title: '联系邮箱：',
                    content: 'haoyunll@haoyunll.cn'
                },
                {
                    title: '总部地址：',
                    content: '福建省福州市鼓楼区铜盘路29号超大大厦四层'
                }
            ],
            productList: [{
                    title: '产品与解决方案',
                    list: [{
                            subTitle: '智慧交通云',
                            path: '/trafficCloud'
                        },
                        {
                            subTitle: '数智工厂',
                            path: '/wisdomPlant'
                        },
                        {
                            subTitle: 'TMS运输',
                            path: '/tms'
                        },
                        {
                            subTitle: '网络货运',
                            path: '/freight'
                        },
                        {
                            subTitle: 'B2B采购商城',
                            path: '/store'
                        },
                        {
                            subTitle: '车后服务',
                            path: '/carMarket'
                        },
                        {
                            subTitle: '设备租赁',
                            path: '/leasing'
                        },
                        {
                            subTitle: 'CRM管理平台',
                            path: '/crm'
                        },
                        {
                            subTitle: '车载哪',
                            path: '/car'
                        }
                    ]
                },
                // {
                //     title: '好运云链',
                //     list: [{
                //         subTitle: '好运云链',
                //         // path: '/'
                //         path: '/cloudChain'
                //     }]
                // },
                {
                    title: '关于我们',
                    list: [{
                        subTitle: '关于我们',
                        // path: '/'
                        path: '/about'
                    }]
                },
            ]
        };
    },
    methods: {
        
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
footer {
    background-color: #000;

    .info-wrap {
        color: #fff;

        .left-info {
            // margin-right: 220px;
            ul {
                li {
                    line-height: 36px;
                }
            }
        }

        .right-info {
            ul {
                margin-right: 70px;
                .item {
                    color: #919191;
                    &:hover{
                        a{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .copy-right {
        margin: 0 auto;
        border-top: 1px #555 solid;
        padding: 28px 0;
        text-align: center;
        color: #fff;
    }
}
</style>
