<template>
<div class="partner-wrap">
    <div class="partner-ul flex">
        <div v-for="item in partnerList" class="scroll-image-list partner-li">
            <div class="scroll-list-body">
                <img :src="item.url" alt="合作伙伴">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'partnerScroll',
    props: {
        // imgList: {
        //   type: Array,
        // },
    },
    data() {
        return {
            partnerList: [{
                    url: require('@/assets/partner.png')
                },
                {
                    url: require('@/assets/partner.png')
                }
            ]
        }
    },
    mounted() {

    },
    destroyed() {

    },
    methods: {

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.partner-wrap {
    width: 1508px;
    height: 331px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -50px;
        bottom: 0;
        width: 150px;
        height: 331px;
        background-color: #fff;
        filter: blur(70px);
        z-index: 999;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 150px;
        height: 331px;
        background-color: #fff;
        filter: blur(70px);
    }

    .partner-ul {
        list-style: none;
        width: 3566px;
        height: 331px;
        animation: move 15s 1s infinite linear;

        .partner-li {
            width: 1783px;
            height: 331px;
            float: left;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @keyframes move {
        to {
            transform: translateX(-1783px)
        }
    }
}
</style>
