import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/demoList',
    name: 'demoList',
    component: () => import('@/views/DemoList.vue')
  },
  {
    path: '/wisdomPlant',
    name: 'wisdomPlant',
    component: () => import('@/views/WisdomPlant.vue')
  },
  {
    path: '/cloudChain',
    name: 'cloudChain',
    component: () => import('@/views/CloudChain.vue')
  },
  {
    path: '/trafficCloud',
    name: 'trafficCloud',
    component: () => import('@/views/TrafficCloud.vue')
  },
  {
    path: '/crm',
    name: 'crm',
    component: () => import('@/views/CRM.vue')
  },
  {
    path: '/tms',
    name: 'tms',
    component: () => import('@/views/TMS.vue')
  },
  {
    path: '/freight',
    name: 'freight',
    component: () => import('@/views/Freight.vue')
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('@/views/Car.vue')
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('@/views/Store.vue')
  },
  {
    path: '/leasing',
    name: 'leasing',
    component: () => import('@/views/Leasing.vue')
  },
  {
    path: '/carMarket',
    name: 'carMarket',
    component: () => import('@/views/CarMarket.vue')
  }
]
const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
