<template>
<!-- 演示 咨询 -->
<section class="demo-wrap">
    <div class="w1300">
        <h1 class="font-size-32">用一个平台 <br />搞定物流产业数字化管理</h1>
        <div class="btn-list flex m-t-30" @click="applyDemoFormOpen">
            <div class="btn demo-btn">预约演示</div>
            <div class="btn consult-btn">立即咨询 ></div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'BottomDemoDiv',
    props: {
        msg: String
    },
    data() {
        return {

        };
    },
    methods: {
        applyDemoFormOpen() {
            this.$emit("applyDemoFormOpen");
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.demo-wrap {
    background: url('@/assets/bg2.png') center no-repeat;
    background-size: cover;
    min-width: 1300px;
    height: 300px;
    color: #fff;
    padding: 50px 0 50px 0;
    .btn-list{
        width: 360px;
        .btn {
            width: 180px;
            height: 54px;
            line-height: 54px;
            text-align: center;
            font-size: 20px;
            cursor: pointer;
        }
    
        .demo-btn {
            background: #FFFFFF;
            color: #001A6C;
        }
    
        .consult-btn {
            border: 1px solid #fff;
            // background-color: rgb(16, 63, 183);
        }
    }
    // margin-top: 168px;
}
</style>
