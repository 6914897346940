<template>
<div>
    <el-dialog title="" :visible.sync="formShow" width="910px" :close-on-click-modal="false">
        <div class="flex flex-row-between container">
            <div class="left">
                <img src="@/assets/form-info-bg.png" alt="">
            </div>
            <div class="right">
                <div class="main-color font-size-32 m-b-10">立即咨询</div>
                <div class="font-size-18 m-b-30">请留下联系方式，我们将尽快与您联系</div>
                <el-form :model="form" :rules="rules">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入您的手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="公司名称" prop="company">
                        <el-input v-model="form.company" placeholder="请输入您的公司名称"></el-input>
                    </el-form-item>
                    <el-form-item label="需求描述" prop="desc">
                        <el-input type="textarea" :rows="4" resize="none" placeholder="请描述您的需求，我们将为您推荐合适的产品服务" v-model="form.desc">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit-btn" type="primary">立即咨询</el-button>
                    </el-form-item>
                </el-form>
                <!-- <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="formShow = false">立即咨询</el-button>
          </div> -->
            </div>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default {
    name: 'applyDemoForm',
    props: {

    },
    data() {
        return {
            formShow: false,
            form: {
                name: '',
                phone: '',
                company: '',
                desc: ''
            },
            rules: {
                name: [{
                    required: true,
                    message: '请输入名字',
                    trigger: 'blur'
                }, ],
                phone: [{
                    required: true,
                    message: '请输入手机号码',
                    trigger: 'blur'
                }, ],
                company: [{
                    required: true,
                    message: '请输入送死名称',
                    trigger: 'blur'
                }, ],
            }
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/deep/.el-dialog__header {
    padding: 0;
}

/deep/ .el-dialog__body {
    padding: 0;
}

.container {
    width: 100%;

    .submit-btn {
        width: 100%;
    }

    .left {
        width: 405px;
    }

    .right {
        width: 505px;
        padding: 30px;
    }
}
</style>
