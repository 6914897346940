<!-- AppLink.vue -->
<template>
    <a v-if="isExternal" :href="to" rel="noopener" target="_blank"><slot/></a>
    <router-link v-else v-bind="$props"><slot /></router-link>
</template>

<script>
import { RouterLink } from 'vue-router'
export default {
    name: 'AppLink',
    props:{ ...RouterLink.props },
    data() {
        return {

        };
    },
    computed:{
        isExternal(){
            return typeof this.to === 'string' && this.to.startsWith('http')
        }
    },
    methods: {
       
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>

</style>
