<template>
<main class="home">
    <div class="header-wrap">
        <navMenu :white="true" :menuIndex="0"/>
        <div class="top-wrap w1300">
            <div class="top-content">
                <!-- <div class="font-size-32">中国最专业的</div> -->
                <div class="line"></div>
                <div class="font-size-40">供应链物流数字化服务商</div>
                <p class="font-size-16 m-t-10">搭建全场景短链路数字化平台，以科技驱动供应链物流，为客户实现数字化转型提供助力</p>
                <ul class="font-size-18 flex m-t-20">
                    <li>专业化</li>
                    <li>标准化</li>
                    <li>智能化</li>
                    <li>数字化</li>
                </ul>
                <el-button type="primary" class="apply-btn m-t-70" @click="$refs.applyDemoForm.formShow = true"><span class="font-bold">申请试用</span> <i class="el-icon-right"></i></el-button>
            </div>
        </div>
        <div class="flex flex-row-between desc-list w1300">
            <div class="flex flex-row-between desc-item" v-for="item in tagList" :key="item.title">
                <div>
                    <h1 class="font-size-26"> <img :src="item.icon" alt=""> {{ item.title }}</h1>
                    <p class="font-size-18">{{ item.content }}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 物流 -->
    <section class="logistics-wrap">
        <div class="w1300  text-center">
            <Title>
                物流产业数字化协同
            </Title>
            <!-- <el-image
              style="width: 1200px; height: 768px"
              :lazy="true"
              :src="@/assets/index/index2.png"
              fit="cover"></el-image> -->

            <img src="@/assets/index/index2.png" alt="">
        </div>
    </section>
    <!-- 产品与服务 -->
    <section class="product-wrap">
        <Title>
            产品服务
        </Title>
        <div class="product-container">
            <div class="product-tag w1300">
                <ul class="product-tag-list flex">
                    <li class="product-tag-item font-szie-24" v-for="(product,index) in productList" :key="index" @click="productTagChange(index)" :class="curProductTagIndex == index ? 'activeProductTag' :''">{{ product.tagTitle }}</li>
                </ul>
            </div>
            <div class="product-content w1300 flex flex-row-between">
                <div class="product-left">
                    <div>
                        <h1 class="font-size-26 m-b-35">{{ productList[curProductTagIndex].tagTitle }}</h1>
                        <p class="font-size-16 m-b-45">{{ productList[curProductTagIndex].content.desc }}</p>
                        <ul class="tag-list flex font-size-16">
                            <li v-for="tag in productList[curProductTagIndex].content.tagList"  :key="tag">{{ tag }}</li>
                        </ul>
                    </div>
                    <router-link class="detail font-size-18" :to="productList[curProductTagIndex].content.path">查看详情 >></router-link>
                </div>
                <div class="product-right">
                    <div class="img">
                        <el-image style="width: 730px; height: 430px" :lazy="true" :src="productList[curProductTagIndex].pic" fit="cover"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- 核心优势 -->
    <section class="core-wrap">
        <div class="w1300">
            <Title>
                核心优势
                <template slot="two-title">链接资源 整合服务 科技赋能 提质增效 阳光创收</template>
            </Title>
            <ul class="core-list flex flex-row-between flex-wrap">
                <li class="core-item" v-for="(item,index) in coreList" :key="item.title">
                    <div class="core-img" :class="'core-img'+index"></div>
                    <h1 class="font-size-20 m-b-10">{{ item.title }}</h1>
                    <p class="font-size-16">{{ item.desc }}</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- <section class="swiper-wrap">
        <div class="w1300" style="padding-top:200px;">
            <h1 class="font-size-36">客户为我们代言</h1>
        </div>
        <div class="swiper-father">
            <div class="swiper-container">
                <el-carousel class="banner-wrap" :interval="5000" height="500px" arrow="always" :autoplay="false" indicator-position="none">
                    <el-carousel-item v-for="item in 4" :key="item">
                        <div class="flex flex-row-between m-t-80">
                            <div class="left">
                                <img src="@/assets/logo3.png" alt="">
                                <p class="font-size-16">与同类产品对比，XXX对高风险驾驶行为的识别率、准确率较高，并且在合作期间，协助我们持续迭代算法，让安全预警和干预能力进一步提升。XXXX的安全监控团队还帮助我们实时处理风险异常，引导司机脱险，提供周期性报告。司机放心，调度省心，我们都很满意。</p>
                            </div>
                            <div class="right">
                                <img class="img" src="@/assets/index3.png" alt="">
                                <div class="info">
                                    <div><img src="@/assets/icon-huojian.png" alt="" class="m-r-10"><span>管车效率提高6倍</span></div>
                                    <div class="m-t-10"><img src="@/assets/icon-wallet.png" alt="" class="m-r-10"><span>一个平台财税全管理</span></div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </section> -->
    <!-- 他们都在用 -->
    <section style="padding-bottom: 160px;">
        <Title>
            他们都在用
        </Title>
        <PartnerScroll />
    </section>
    <!-- 演示 咨询 表单 -->
    <BottomDemoDiv @applyDemoFormOpen="applyDemoFormOpen" />
    <!-- 右侧悬浮导航 -->
    <SuspendBar @applyDemoFormOpen="applyDemoFormOpen" />
    <!-- 申请表单 -->
    <applyDemoForm ref="applyDemoForm" />
</main>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {

    },
    data() {
        return {
            curProductTagIndex: 0,
            tagList: [{
                    icon: require('@/assets/icon1.png'),
                    title: '数智化解决方案',
                    content: '实现端到端全流程可视化，实现上下游协同，提升整体效率'
                },
                {
                    icon: require('@/assets/icon2.png'),
                    title: '物流全链条透明',
                    content: '实现人、车、货、仓及其设施、设备的全面透明、可视可控'
                },
                {
                    icon: require('@/assets/icon3.png'),
                    title: 'IoT智能硬件',
                    content: '物流物联网设备全面智能化，全场景协同、优化'
                }
            ],
            productList: [{
                    tagTitle: '数智工厂',
                    pic: require('@/assets/index/product0.png'),
                    content: {
                        desc: '面向厂区或园区的物流综合管理平台，通过GIS地图和北斗卫星技术，实现车辆违规告警、智能排号排队、厂内路线导航等功能，让厂区变得更聪明、更透明。',
                        tagList: ['供应链协同', '智慧决策', '深度场景应用'],
                        path: '/wisdomPlant'
                    }
                },
                {
                    tagTitle: '网络货运',
                    pic: require('@/assets/index/product1.png'),
                    content: {
                        desc: '平台结合运力资源、货源组织、过程监控、线上交易、线下服务等功能为一体，为货物和物流公司提供高效解决方案及多样化服务。其主要包含前端与后端两部分，前端面向客户提供多样化赋能服务，包含“联车宝“及“联车宝"两款APP及合伙人PC端产品，后端为客户、数据管理平台，实现客户、运单管理、运力、数据分析、统计、结算等功能。',
                        tagList: ['数字化运力', '运输透明', '税务规范'],
                        path: '/freight'
                    }
                },
                {
                    tagTitle: '交通云',
                    pic: require('@/assets/index/product2.png'),
                    content: {
                        desc: '依托移动互联网、物联网、大数据、云计算、人工智能等技术搭建链接”人，车，物，路，站”的五维数据中台基座，输出各类应用系统，为客户定制解决痛点、提高效率的方案，并提供数据精准分析和运营决策能力。',
                        tagList: ['物联网', '车联网', '数字化'],
                        path: '/trafficCloud'
                    }
                },
                {
                    tagTitle: 'TMS',
                    pic: require('@/assets/index/product3.png'),
                    content: {
                        desc: '致力于提供端到端的解决方案，通过与相关系统和IoT设备集成，提供了一个精细化实时协同信息平台，助力企业数字化升级。连接有运输需求和运力资源的双方，打通供应链上下游的单据、物流与结算信息，从而真正达到企业的单据、物流与结算信息，从而真正达到企业',
                        tagList: ['跨域联动', '智能调度', '自动化计费与出账'],
                        path: '/tms'
                    }
                },
                {
                    tagTitle: 'B2B电商',
                    pic: require('@/assets/index/product4.png'),
                    content: {
                        desc: '好运S2B2C供应链平台系统，通过整合行业上游供应链资源，实现供应商直采，搭建供应链新零售平台;自身功能的完善性，能够让企业更加规范化、系统化的做管理流程，赋能渠道提升业务处理效率和客户体验，构建互联网+传统产业，提升供应链效率，进一步增强品牌影响力。',
                        tagList: ['上游链接', '网络化协同', '渠道订货'],
                        path: '/store'
                    }
                },
                {
                    tagTitle: '设备租赁',
                    pic: require('@/assets/index/product5.png'),
                    content: {
                        desc: '搭建交易服务系统、设备管理系统、经营租赁 系统、运营管理系统，通过数据交互、数据分析形成一套完整的租赁平台，企业借助平台可以将社会上闲置的设备放入交易平台中，灵活的租赁方式和规范的系统流程，可以使设备能够以最快最优的方式租赁使用，同时帮助租赁企业建立完善的内部风险控制体系，形成良好的资产分类管理制度、承租人信用评估制度、事后追偿和处置制度奠定数据基础。',
                        tagList: ['节约成本', '轻资产', '随租随还'],
                        path: '/leasing'
                    }
                },
                {
                    tagTitle: 'CRM',
                    pic: require('@/assets/index/product6.png'),
                    content: {
                        desc: 'CRM实现360°客户管理视图、可视化销售管 道、智能表单、自动化工作流、统计分析、销售自动化，帮助销售人员从琐碎的工作中脱离出来，获得可持续支持，从而有更多时间专注于核心业务，助力企业轻松实现数字化转型。',
                        tagList: ['精准画像', '决策报表', '可视化的L2C'],
                        path: '/crm'
                    }
                },
                {
                    tagTitle: '车后市场',
                    pic: require('@/assets/index/product7.png'),
                    content: {
                        desc: '搭建车后产业链体系产品，为货主、承运商、司机提供燃油、ETC、保险服务等多样化服务产品，并与网络货运平台对接实现运输过程采购与结算，出具实时账单。',
                        tagList: ['油邦客', '好运保', '易路票'],
                        path: '/carMarket'
                    }
                },
            ],
            coreList: [{
                    title: '领先的功能架构',
                    desc: '功能模块SaaS+第三方交易平台，兼容用户标准化和定制化需求。统一的账户体系+开放式资源库+闭环式管理。移动端(小程序) +web端联动应用'
                },
                {
                    title: '融合的技术应用',
                    desc: '“云、物、移、大、智、链”技术 (云计算、物联网、移动5G、大数据、人工智能和区块链)融合运用，加速信息流转，智能匹配各方优势资源'
                },
                {
                    title: '开放的资源渠道',
                    desc: '拥有海量的供需方及商机信息，面向平台所有用户开放共享、无缝连接、精准匹配，高效达成交易'
                },
                {
                    title: '专业的运营服务',
                    desc: '线上线下相结合的的技术服务体系，跨行业组合专业化服务队伍，可为用户同时实现降本、增效、阳光、创收四大目标'
                },
                {
                    title: '灵活的金融配置',
                    desc: '运用云物移大智链等互联网科技力量，为金融场景提供数据驱动、场景验真、智能风控、资产管理等 服务，助力产融结合、共赢发展'
                },
                {
                    title: '快速的部署应用',
                    desc: '针对不同的业务场景及产品，可提供平台SaaS、多种云部署模式，实现快速交付，实施成本低、部署速度快'
                }
            ],

        };
    },
    created() {

    },
    mounted() {

    },
    destroyed() {

    },
    methods: {
        productTagChange(index) {
            this.curProductTagIndex = index
        },
        applyDemoFormOpen() {
            this.$refs.applyDemoForm.formShow = true
        }
    },
}
</script>

<style lang="scss" scoped>
.home {
    .header-wrap {
        background: url('@/assets/index-header-bg.png') center no-repeat;
        background-size: cover;
        // max-width: 1600px;
        min-width: 1400px;
        height: 924px;
        position: relative;
        color: #fff;

        .top-wrap {
            .top-content {
                width: 511px;
                max-width: 1300px;
                padding-top: 150px;

                .line {
                    width: 515px;
                    height: 2px;
                    background: #A9A9A9;
                    position: relative;
                    margin: 25px 0;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 114px;
                        height: 5px;
                        background: #F5881A;
                    }
                }

                p {
                    line-height: 28px;
                }

                ul {
                    li {
                        width: 102px;
                        height: 54px;
                        line-height: 54px;
                        text-align: center;
                        background: rgba($color: #666, $alpha: .2);
                        border-radius: 5px;
                        color: #fff;
                        margin-right: 28px;
                    }
                }
            }
        }

        .desc-list {
            position: absolute;
            bottom: -88px;
            left: 50%;
            transform: translate(-50%);
            z-index: 999;

            .desc-item {
                width: 410px;
                height: 188px;
                border-radius: 10px;
                box-shadow: 0px 2px 15px 1px rgba(151, 151, 151, 0.2);
                background-color: #fff;
                padding: 30px 50px;
                color: #555;
                transition: all .5s;
                &:hover {
                    transform: translateY(-10px);
                }
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 12px;
                }

                p {
                    margin-top: 10px;
                }
            }
        }

        .apply-btn {
            width: 178px;
            height: 54px;
            font-size: 20px;
        }
    }

    .logistics-wrap {
        background-color: rgb(245, 246, 250);
        padding: 125px 0 95px;
    }

    .product-wrap {
        width: 1400px;
        height: 900px;
        margin: 0 auto 180px;

        .product-container {
            position: relative;

            .product-tag {
                height: 100px;
                background: #FFFFFF;
                box-shadow: 0px 0px 18px 0px rgba(151, 151, 151, 0.2);

                .product-tag-list {
                    padding: 0 30px;

                    .product-tag-item {
                        width: 145px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        color: #555;
                        font-size: 26px;
                        margin-right: 20px;
                        cursor: pointer;
                        position: relative;
                        z-index: 99;

                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '';
                            background-color: transparent;
                            height: 5px;
                            display: inline-block;
                            width: 145px;
                        }

                        &:hover {
                            font-weight: bold;
                        }
                    }

                    .activeProductTag {
                        font-weight: bold;

                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 5px;
                            width: 145px;
                            content: '';
                            background: #3699FF;
                        }
                    }
                }
            }

            .product-content {
                margin-top: 120px;

                .product-left {
                    position: relative;
                    height: 430px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    .tag-list {
                        li {
                            // width: 120px;
                            padding: 0 15px;
                            height: 54px;
                            line-height: 54px;
                            text-align: center;
                            background: #E0EFFB;
                            border-radius: 5px;
                            margin-right: 10px;
                        }
                    }

                    p {
                        width: 500px;
                        line-height: 28px;
                    }

                    .detail {
                        color: #3699FF;
                        // position: absolute;
                        // bottom: 0;
                        // right: 20px;
                    }
                }

                .product-right {
                    .img {
                        width: 730px;
                        height: 430px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .core-wrap {
        background-color: rgb(245, 246, 250);
        padding-bottom: 150px;

        .core-list {
            width: 1295px;
            margin: 0 auto;

            // height: 500px;
            .core-item {
                width: 415px;
                height: 290px;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 30px 40px;

                &:hover {
                    background: url('@/assets/core-bg.png') center no-repeat;

                    h1,
                    p {
                        color: #fff;
                    }

                    .core-img0 {
                        background-image: url('@/assets/core0-1.png');
                    }

                    .core-img1 {
                        background-image: url('@/assets/core1-1.png');
                    }

                    .core-img2 {
                        background-image: url('@/assets/core2-1.png');
                    }

                    .core-img3 {
                        background-image: url('@/assets/core3-1.png');
                    }

                    .core-img4 {
                        background-image: url('@/assets/core4-1.png');
                    }

                    .core-img5 {
                        background-image: url('@/assets/core5-1.png');
                    }
                }

                &:nth-child(n+4) {
                    margin-top: 25px;
                }

                .core-img {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 20px;
                }

                .core-img0 {
                    background-image: url('@/assets/core0-0.png');
                }

                .core-img1 {
                    background-image: url('@/assets/core1-0.png');
                }

                .core-img2 {
                    background-image: url('@/assets/core2-0.png');
                }

                .core-img3 {
                    background-image: url('@/assets/core3-0.png');
                }

                .core-img4 {
                    background-image: url('@/assets/core4-0.png');
                }

                .core-img5 {
                    background-image: url('@/assets/core5-0.png');
                }

                p {
                    line-height: 30px;
                }
            }
        }
    }

    .swiper-wrap {
        background: url('@/assets/index/bg1.png') center no-repeat;
        background-size: cover;
        min-width: 1400px;
        height: 1067px;
        color: #fff;

        .swiper-father {
            width: 1450px;
            height: 500px;
            // border: 1px solid red;
            position: relative;
            margin: 80px auto 0;

            .swiper-container {
                position: relative;
                width: 1450px;
                //  height: 424px;
                height: 500px;

                // margin-top: 350px;
                .banner-wrap {
                    padding: 0 80px;

                    /deep/.el-carousel__arrow--left {
                        left: -95px;
                    }

                    /deep/.el-carousel__arrow--right {
                        right: -95px;
                    }

                    /deep/.el-carousel__arrow {
                        height: auto;
                        width: auto;
                        font-size: 70px;
                        background-color: transparent;
                    }
                }

                .left {
                    width: 385px;
                    font-size: 18px;
                    line-height: 30px;
                    height: 424px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 107px;
                        height: 66px;
                        margin-bottom: 55px;
                    }
                }

                .right {
                    position: relative;

                    .info {
                        position: absolute;
                        right: 0;
                        top: -76px;
                        min-width: 247px;
                        height: 100px;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 35px 3px rgba(151, 151, 151, 0.2);
                        border-radius: 10px;
                        z-index: 999;
                        color: #555;
                        font-size: 14px;
                        padding: 20px 30px;
                    }
                }

                .img {
                    width: 760px;
                    height: 424px;
                }

            }

            .swiper-button-prev {
                left: -60px;
            }

            .swiper-button-next {
                right: -60px;
            }
        }
    }
}
</style>
